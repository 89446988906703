import { Suspense, lazy } from 'react';
import PageErrorBoundary from '../../components/PageErrorBoundary';
import Loading from '../../components/Loading';

const Step1 = lazy(() => import('./Index'));
function SuspendedStep1() {
  return (
    <PageErrorBoundary>
      <Suspense fallback={<Loading />}>
        <Step1 />
      </Suspense>
    </PageErrorBoundary>
  );
}

export default SuspendedStep1;
