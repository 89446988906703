import { Suspense, lazy } from 'react';
import PageErrorBoundary from '../../components/PageErrorBoundary';
import Loading from '../../components/Loading';

const Step2 = lazy(() => import('./Index'));
function SuspendedStep2() {
  return (
    <PageErrorBoundary>
      <Suspense fallback={<Loading />}>
        <Step2 />
      </Suspense>
    </PageErrorBoundary>
  );
}

export default SuspendedStep2;
