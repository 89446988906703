import axios, { AxiosInstance } from 'axios';
import { B2BOrdersRes } from '../types';

export enum Status {
  pending = 'pending',
  inProgress = 'inprogress',
  done = 'done',
}

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers:
    process.env.REACT_APP_ENV === 'local'
      ? {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        }
      : undefined,
});

export const updateAxiosAuthToken = (token: string) => {
  if (window.localStorage) {
    window.localStorage.setItem('token', token);
  }

  axiosInstance.interceptors.request.use(config => {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });
};

export type B2BOrdersParamsProps = {
  page: number;
  searchQuery: string;
};

export type IndividualOrdersParamsProps = {
  page: number;
  baseorderid: string;
};
export const getB2BOrdersData = async (
  passedParams: B2BOrdersParamsProps,
): Promise<B2BOrdersRes> => {
  const params: Record<string, any> = {
    page: passedParams.page,
  };

  if (passedParams.searchQuery) {
    params.search = passedParams.searchQuery;
  }

  const res = await axiosInstance.get<B2BOrdersRes>('/api/orders', {
    params,
  });
  return res.data;
};

export const getIndividualOrderData = async (
  params: IndividualOrdersParamsProps,
) => {
  const res = await axiosInstance.get('/api/individualOrder', {
    params,
  });

  return res.data;
};

export type FileUploadProps = {
  companyName: string;
  orderId: string;
  file: File;
};
export const uploadFile = async (fileData: FileUploadProps) => {
  const res = await axiosInstance.post('/api/fileUpload', fileData);
  return res.data;
};

export type MarksAsApprovedProps = {
  id: number;
};

export const approveOrder = async (params: MarksAsApprovedProps) => {
  const res = await axiosInstance.get(`api/b2borderapprovefront`, {
    params,
  });
  return res.data;
};

export type CreateOrderParamsProps = {
  order_id: number;
};
export const createIndividualOrder = async (data: CreateOrderParamsProps) => {
  const res = await axiosInstance.post('/api/individualOrder', data);
  return res.data;
};
