import { NavLink } from 'react-router-dom';
import cocoaDolceLogo from '../assets/cocoa_logo.svg';

function AppHeader() {
  return (
    <header className='w-full border-b border-gray-400'>
      <nav className='bg-white border-gray-200 dark:bg-gray-900'>
        <div className='max-w-screen-2xl border-gray-500 flex flex-wrap items-center space-x-20 justify-start  mx-auto p-4'>
          <NavLink to='/' className='flex items-start'>
            <img
              src={cocoaDolceLogo}
              className='h-8 mr-3'
              alt='Cocoa-dolce Logo'
            />
          </NavLink>
          <ul className='font-medium flex flex-col p-0 border-gray-100 rounded-lg md:flex-row md:space-x-8 mt-0 md:border-0 dark:bg-gray-800 md:dark:bg-gray-900  dark:border-gray-700'>
            <li className='dark:border-gray-700 lg:inline lg:border-b-0'>
              <NavLink
                to='/'
                className='flex items-center py-0 w-full text-primary-600 hover:text-primary-600 dark:text-primary-500'
                aria-current='page'
              >
                <svg
                  className='mr-2 w-4 h-4'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    d='M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z'
                    clipRule='evenodd'
                  />
                </svg>
                Cocoa Dolce App
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default AppHeader;
