import 'flowbite';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { updateAxiosAuthToken } from './services/api.service';

if (process.env.REACT_APP_ENV !== 'local') {
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.has('token')
    ? searchParams.get('token')
    : window.localStorage?.getItem('token');

  if (token) {
    updateAxiosAuthToken(token);
  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
