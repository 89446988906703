import { Suspense, lazy } from 'react';
import PageErrorBoundary from '../../components/PageErrorBoundary';
import Loading from '../../components/Loading';

const IndividualPage = lazy(() => import('./Index'));
function SuspendedIndividualOrderPage(props: any) {
  return (
    <PageErrorBoundary>
      <Suspense fallback={<Loading />}>
        <IndividualPage {...props} />
      </Suspense>
    </PageErrorBoundary>
  );
}

export default SuspendedIndividualOrderPage;
