import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ToastContainer } from 'react-toastify';
import DashboardPage from './pages/Dashboard/SuspendedDashboardPage';
import Step1 from './pages/OrderCreationStep1/SuspendedStep1';
import Step2 from './pages/OrderCreationStep2/SuspendedStep2';
import Step3 from './pages/OrderCreationStep3/SuspendedStep3';
import NotFoundPage from './pages/NotFoundPage/Index';
import CreateOrderPage from './pages/CreateOrderPage/SuspendedCreateOrderPage';
import AppQueryClientProvider from './contexts/react-query-context';
import SuspendedIndividualOrderPage from './pages/IndividualOrderPage/SuspendedIndividualOrderPage';
import 'react-toastify/dist/ReactToastify.css';
import AppHeader from './components/AppHeader';

function App() {
  return (
    <AppQueryClientProvider>
      {/* {process.env.REACT_APP_ENV === 'local' && <ReactQueryDevtools />} */}
      <ToastContainer
        bodyClassName='successToast'
        position='top-center'
        hideProgressBar
        autoClose={3000}
        newestOnTop
        theme='light'
      />
      <BrowserRouter>
        <div className='w-full min-h-screen bg-gray-50 dark:bg-gray-900'>
          <AppHeader />
          <Routes>
            <Route index element={<DashboardPage />} />
            <Route
              path=':orderId/individual-orders'
              element={<SuspendedIndividualOrderPage />}
            />
            <Route
              path=':individual/create-order'
              element={<CreateOrderPage />}
            >
              <Route path='step1' element={<Step1 />} />
              <Route path='step2' element={<Step2 />} />
              <Route path='step3' element={<Step3 />} />
            </Route>
            <Route path='*' element={<NotFoundPage />} />
          </Routes>
        </div>
      </BrowserRouter>
    </AppQueryClientProvider>
  );
}

export default App;
