import { lazy } from 'react';
import PageErrorBoundary from '../../components/PageErrorBoundary';

const Step3 = lazy(() => import('./Index'));
function SuspendedStep3(props: any) {
  return (
    <PageErrorBoundary>
      <Step3 {...props} />
    </PageErrorBoundary>
  );
}

export default SuspendedStep3;
